// Block hover
// let target = document.querySelector("main .section-blocks .block-wrapper .block");
//
let _ = require('lodash');

const targets = document.querySelectorAll('main .section-blocks .block-wrapper .block');

if (! _.isEmpty(targets)) {
    Array.from(targets).forEach(target => {
        target.addEventListener("mouseenter", function() {
            target.parentElement.classList.add('hover');
        });

        target.addEventListener("mouseleave", function() {
            target.parentElement.classList.remove('hover');
        });

        let targetUrl = target.getElementsByTagName('a')[0].getAttribute('href');
        target.classList.add("clickable");

        target.onclick = function () {
            window.location = targetUrl;
        }
    });
}

// Teaser clickable
const newsTeasers = document.querySelectorAll('.view-news-teasers .news-teaser .inner');

if (!_.isEmpty(newsTeasers)) {
    Array.from(newsTeasers).forEach(teaser => {
        let teaserUrl = teaser.getElementsByTagName('a')[0].getAttribute('href');
        teaser.classList.add("clickable");

        teaser.onclick = function () {
            window.location = teaserUrl;
        }
    });
}